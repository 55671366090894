import throttle from "lodash.throttle";
import { minTablet } from "../../../helpers/resize";

class HeaderScroll {
	constructor(el) {
		this.el = el;
		this.isScrolled = false;
		this.menuContent = el.querySelector("[data-menu-content]");
		this.menuButton = el.querySelector("[data-menu-button]");
		this.throttledScroll = throttle(this.onScroll.bind(this), 10);
		this.prevScrollpos = window.scrollY;
		this.navbar = document.querySelector("[data-header]");
	}

	handleNavbarScroll() {
		if (!this.navbar) return;

		if (minTablet.matches) {
			const currentScrollPos = window.scrollY;
			if (this.prevScrollpos < currentScrollPos && currentScrollPos > 200) {
				this.navbar.style.transform = "translateY(-100%)";
			} else {
				this.navbar.style.transform = "translateY(0%)";
			}
			this.prevScrollpos = currentScrollPos;
		}
	}

	onScroll() {
		this.handleNavbarScroll();

		if (window.scrollY > 200 && !this.isScrolled && minTablet.matches) {
			document.body.classList.add("is-scrolled");
			// this.menuButton.hidden = false
			// this.menuContent.hidden = true
			this.isScrolled = true;
		} else if (window.scrollY <= 200 && this.isScrolled && minTablet.matches) {
			document.body.classList.remove("is-scrolled");
			// this.menuButton.hidden = true
			// this.menuContent.hidden = false
			this.isScrolled = false;
			this.closeMenu();
		}
	}

	closeMenu() {
		document.body.classList.remove("is-menu-open");
		this.menuButton.querySelector("[data-text]").innerText = "Menu";
		this.el.removeEventListener("keydown", this.trapFocus);
		this.menuButton.setAttribute("aria-expanded", "false");
		this.isHidden = true;

		if (this.onCloseCallback && typeof this.onCloseCallback === "function") {
			this.onCloseCallback();
		}
	}

	init() {
		window.addEventListener("scroll", this.throttledScroll);
		window.addEventListener("resize", this.throttledScroll);
	}

	destroy() {
		window.removeEventListener("scroll", this.throttledScroll);
		window.removeEventListener("resize", this.throttledScroll);
	}
}

export default HeaderScroll;
