import throttle from "lodash.throttle";

const resize = (callback, wait = 600) => {
	window.addEventListener("resize", throttle(callback, wait));
};

const minAll = window.matchMedia("(min-width: 320px)");
const minDesktop = window.matchMedia("(min-width: 1200px)");
const minTablet = window.matchMedia("(min-width: 1100px)");

export { minAll, minDesktop, minTablet };

export default resize;
