class CaseStudy {
	constructor(el, dataAttribute = "scroll-anchor=true") {
		this.el = el;
		this.dataAttribute = dataAttribute;
		this.categoryLinks = [...document.querySelectorAll(".category-link")]; // Assuming the category links are present in the entire document
	}

	urlMatches() {
		const pattern = /\/case-study-categories\/[^/]+\/$/;
		return pattern.test(window.location.pathname);
	}

	scrollToAnchor() {
		const element = this.el.querySelector(`[data-${this.dataAttribute}]`);
		if (element) {
			element.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	}

	bindCategoryLinks() {
		this.categoryLinks.forEach((link) => {
			link.addEventListener("click", () => {
				sessionStorage.setItem("autoScrollToAnchor", "true");
			});
		});
	}

	init() {
		// Bind category link click listeners
		this.bindCategoryLinks();

		// If the URL matches the pattern and the sessionStorage flag is set, trigger auto-scroll
		if (
			this.urlMatches() &&
			sessionStorage.getItem("autoScrollToAnchor") === "true"
		) {
			sessionStorage.removeItem("autoScrollToAnchor"); // Clear the flag
			window.addEventListener("DOMContentLoaded", () => this.scrollToAnchor());
		}
	}
}

export const render = (el, dataAttribute) => {
	const caseStudy = new CaseStudy(el, dataAttribute);
	caseStudy.init();
};

export default CaseStudy;
