// let prevLength = document.querySelector('[data-all-articles]')
//   ? document.querySelector('[data-all-articles]').children.length
//   : 0

// const getElementScrollPosition = (localEl) => {
//   const posY =
//     localEl.children[prevLength].getBoundingClientRect().top + window.scrollY
//   return posY - 100
// }

// const scrollToElement = (localEl) => {
//   const newElements = [...localEl.children].filter(
//     (el, index) => index > prevLength
//   )

//   newElements.forEach((el) => {
//     el.style.opacity = 0.5
//   })

//   setTimeout(() => {
//     window.scrollTo({
//       top: getElementScrollPosition(localEl),
//       left: 0,
//       behavior: 'smooth',
//     })

//     newElements.forEach((el) => {
//       el.style.opacity = 1
//     })

//     newElements[0].focus()
//     prevLength = localEl.children.length
//   }, 500)
// }

// const setButtonAttributes = (localBut, remoteURL) => {
//   localBut.setAttribute('data-url', remoteURL)
//   localBut.textContent = 'Load more'
//   localBut.disabled = false
// }

class Pagination {
	constructor(el) {
		this.el = el;

		this.paginationScroll = this.el.querySelector("[data-pagination-scroll]");
		this.paginationWrap = this.el.querySelector("[data-pagination-wrap]");

		window.addEventListener("resize", this.onResize.bind(this));
		this.onResize();

		// this.paginationButton = el.querySelector('[data-pagination-button]')

		// if (this.paginationButton) {
		//   this.paginationButton.addEventListener(
		//     'click',
		//     this.handlePaginationClick.bind(this)
		//   )
		// }
	}

	onResize() {
		if (this.paginationWrap.scrollWidth > this.paginationWrap.clientWidth) {
			this.paginationWrap.classList.add("c-pagination__wrap--scrollable");
			this.paginationScroll.classList.add("flex");
			this.paginationScroll.classList.remove("hidden");
		} else {
			this.paginationWrap.classList.remove("c-pagination__wrap--scrollable");
			this.paginationScroll.classList.remove("flex");
			this.paginationScroll.classList.add("hidden");
		}
	}

	// getHTML(url, callback) {
	//   if (!window.XMLHttpRequest) return
	//   const xhr = new XMLHttpRequest()

	//   xhr.onload = function () {
	//     if (callback && typeof callback === 'function') {
	//       callback(this.responseXML)
	//     }
	//   }

	//   xhr.open('GET', url)
	//   xhr.responseType = 'document'
	//   xhr.send()
	// }

	// handlePaginationClick(e) {
	//   e.preventDefault()

	//   const localURL = this.el.querySelector('[data-pagination-button]').dataset
	//     .url

	//   this.paginationButton.textContent = 'Loading...'
	//   this.paginationButton.disabled = true

	//   this.getHTML(localURL, function (response) {
	//     const localEl = document.querySelector('[data-all-articles]')
	//     const remoteEl = response.querySelector('[data-all-articles]')
	//     const remoteBut = response.querySelector('[data-pagination-button]')
	//     const localBut = document.querySelector('[data-pagination-button]')

	//     localEl.innerHTML = localEl.innerHTML + remoteEl.innerHTML

	//     if (typeof remoteBut != 'undefined' && remoteBut != null) {
	//       const remoteURL = remoteBut.dataset.url

	//       setButtonAttributes(localBut, remoteURL)
	//       scrollToElement(localEl)
	//     } else {
	//       localBut.remove()
	//     }
	//   })
	// }
}
export default Pagination;
