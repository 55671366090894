import { minTablet } from "../../../helpers/resize";
import { trapFocus } from "../../../helpers/trapFocus";
import Submenu from "./submenu";
import "./megaMenuTabs";

class BurgerMenu {
	windowWidth;
	constructor(el, args = {}) {
		this.el = el;
		this.onOpenCallback = args.onOpenCallback;
		this.onCloseCallback = args.onCloseCallback;
		this.menuButton = el.querySelector("[data-menu-button]");
		this.menuButtonMobileClose = el.querySelector("[data-mobile-close]");
		this.menuContent = el.querySelector("[data-menu-content]");
		this.searchButton = el.querySelector("[data-search-mobile-button]");
		this.submenus = [...el.querySelectorAll("[data-submenu]")];
		this.submenuButtons = [...el.querySelectorAll("[data-submenu-button]")];
		this.isHidden = true;
		this.currentOpenIndex = null;
		this.submenuInstances = [];
		this.windowWidth = this.viewportWidth();

		/* Events */
		this.initToggleMenu = this.openMobileMenu.bind(this);
		this.initMobileCloseMenu = this.closeMobileMenu.bind(this);
		this.trapFocus = this.trapFocusInMenu.bind(this);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
	}

	get headerHeight() {
		return this.el.clientHeight;
	}

	viewportWidth() {
		return Math.max(
			document.documentElement.clientWidth || 0,
			window.innerWidth || 0,
		);
	}

	handleOutsideClick(e) {
		if (!this.menuContent.contains(e.target) && e.target !== this.menuButton) {
			this.close();
		}
	}

	resizeMenu() {
		const newViewportWidth = this.viewportWidth();
		if (newViewportWidth !== this.windowWidth) {
			if (!minTablet.matches) {
				// Below minTablet size, close the menu
				this.close();
				this.menuButton.hidden = false;
			} else {
				// Above minTablet size, ensure menuContent is shown
				this.menuContent.hidden = false;
				this.menuButton.hidden = true;
				this.isHidden = false;
			}
		}
		this.windowWidth = newViewportWidth;
	}

	trapFocusInMenu(e) {
		const escIsPressed = e.keyCode === 27 || e.key === "Esc";

		trapFocus(e, this.el);

		if (escIsPressed) {
			this.close();
			this.menuButton.focus();
		}
	}

	allowMenuLinksFocus() {
		const menuLinks = [...this.menuContent.querySelectorAll("a")];
		menuLinks.forEach((el) => {
			el.setAttribute("tabindex", "0");
		});
	}

	preventMenuLinksFocus() {
		const menuLinks = [...this.menuContent.querySelectorAll("a")];
		menuLinks.forEach((el) => {
			el.setAttribute("tabindex", "-1");
		});
	}

	open() {
		this.menuContent.hidden = false;
		this.menuButton.hidden = false;
		this.allowMenuLinksFocus();

		if (this.onOpenCallback && typeof this.onOpenCallback === "function") {
			this.onOpenCallback();
		}

		setTimeout(() => {
			document.body.classList.add("is-menu-open");
			//disableBodyScroll(this.menuContent)
			//this.menuButton.querySelector('[data-text]').innerText = 'Close'
			this.menuButton.querySelector("[data-menu-icon-burger]").hidden = true;
			this.menuButton.querySelector("[data-menu-icon-close]").hidden = false;
			this.el.addEventListener("keydown", this.trapFocus);
			this.menuButton.setAttribute("aria-expanded", "true");
			this.isHidden = false;
		}, 50);

		document.addEventListener("mousedown", this.handleOutsideClick);
	}

	close() {
		this.menuButton.hidden = false;
		this.menuContent.hidden = true;

		document.body.classList.remove("is-menu-open");
		//enableBodyScroll(this.menuContent)
		//this.menuButton.querySelector('[data-text]').innerText = 'Menu'
		this.menuButton.querySelector("[data-menu-icon-burger]").hidden = false;
		this.menuButton.querySelector("[data-menu-icon-close]").hidden = true;
		this.el.removeEventListener("keydown", this.trapFocus);
		this.menuButton.setAttribute("aria-expanded", "false");
		this.isHidden = true;

		if (this.onCloseCallback && typeof this.onCloseCallback === "function") {
			this.onCloseCallback();
		}

		document.removeEventListener("mousedown", this.handleOutsideClick);
	}

	handleClick() {
		if (this.isHidden) {
			this.open();
		} else {
			this.close();
		}
	}

	openMobileMenu(e) {
		this.menuButton.setAttribute("aria-expanded", this.isHidden);
		if (this.isHidden && e.target === this.menuButton) {
			this.open();
		}
	}

	closeMobileMenu() {
		this.menuButton.setAttribute("aria-expanded", this.isHidden);
		this.close();
	}

	handleSearchButtonClick() {
		this.close(); // Close the mobile menu

		const event = new Event("openSearchEvent");
		window.dispatchEvent(event);
	}

	initialStates() {
		if (window.scrollY <= 200 && minTablet.matches) {
			this.menuButton.hidden = true;
			this.menuContent.hidden = false;
			this.isHidden = false;
		} else if (!minTablet.matches) {
			this.menuContent.hidden = true;
			this.isHidden = true;
		}
	}

	init() {
		this.initialStates();
		this.menuButton.addEventListener("click", this.initToggleMenu);
		this.menuButtonMobileClose.addEventListener(
			"click",
			this.initMobileCloseMenu,
		);
		this.searchButton.addEventListener(
			"click",
			this.handleSearchButtonClick.bind(this),
		);
		this.el.classList.add("has-burger-menu");
		// window.addEventListener('resize', this.throttledResize)
		window.addEventListener("resize", this.resizeMenu.bind(this));

		this.initSubmenus();
	}

	destroy() {
		this.menuButton.removeEventListener("click", this.initToggleMenu);
		// window.removeEventListener('resize', this.throttledResize)
		this.el.classList.remove("has-burger-menu");
		this.menuContent.hidden = false;
		this.isHidden = false;
		this.destroySubmenus();
	}

	getSubmenuFromDataAttribute(button) {
		const submenuIndex = button.dataset.submenuButton;

		return this.submenus.find((el) => {
			return el.dataset.submenu === submenuIndex;
		});
	}

	initSubmenus(options) {
		this.submenuInstances = this.submenuButtons.map((button) => {
			const submenuContent = this.getSubmenuFromDataAttribute(button);
			return new Submenu(
				button,
				submenuContent,
				this.el,
				options || {
					useHeight: true,
				},
			);
		});

		this.submenuInstances.forEach((submenuInstance) => {
			submenuInstance.init();
		});
	}

	destroySubmenus() {
		if (!this.submenuInstances.length) return;

		this.submenuInstances.forEach((submenu) => submenu.destroy());
	}
}

export default BurgerMenu;
