import Menu from "./components/burgerMenu";
import HeaderScroll from "./components/headerScroll";
import HeaderSearch from "./components/headerSearch";

class MenuDropdown {
	constructor(el) {
		this.burgerMenuInitialized = false;
		this.searchInitialized = false;
		this.menu = new Menu(el);
		this.search = new HeaderSearch(el, {
			onSearchOpenCallback: this.closeOpenMenus.bind(this),
		});
		this.scroller = new HeaderScroll(el);

		/* Initialize */
		this.initMenu();
		this.search.initSearch();
		this.scroller.init();
	}

	closeOpenMenus() {
		this.menu.submenuInstances.forEach((submenu) => {
			submenu.close();
		});
	}

	initMenu() {
		document.body.classList.add("has-js-menu");

		this.menu.init();
		this.burgerMenuInitialized = true;
	}
}

export default MenuDropdown;
