import { enableBodyScroll } from "body-scroll-lock";
class Submenu {
	constructor(button, submenu, el, submenuOptions) {
		this.el = el;
		this.button = button;
		this.submenu = submenu;
		this.header = Array.from(document.querySelectorAll("header"));
		this.topMenu = Array.from(document.querySelectorAll(".g-menu__wrapper"));
		this.links = [...submenu.querySelectorAll("a")];
		this.list = submenu.querySelector("[data-submenu-list]");
		this.submenus = [...el.querySelectorAll("[data-submenu]")];
		this.megamenu = el.querySelector("[data-megamenu]");
		this.megaContainer = [...el.querySelectorAll("[data-megamenu-container]")];
		this.submenuButtons = [...el.querySelectorAll("[data-submenu-btn]")];
		this.submenuOptions = submenuOptions;

		/* Events */
		this.boundOnClick = this.onClick.bind(this);
		window.addEventListener("resize", this.onResize.bind(this));
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		this.trapFocusInSubmenu = this.trapFocusInSubmenu.bind(this);
	}

	trapFocusInSubmenu(e) {
		const escIsPressed = e.keyCode === 27 || e.key === "Escape";

		if (escIsPressed) {
			this.closeSubmenu(this.submenu, this.button, this.links);
		}
	}

	handleOutsideClick(e) {
		if (!this.submenu.contains(e.target) && e.target !== this.button) {
			this.closeSubmenu(this.submenu, this.button, this.links);
		}
	}

	toggleSubmenu() {
		if (this.submenu.dataset.open === "true") {
			this.closeSubmenu(this.submenu, this.button, this.links);
		} else {
			this.closeOthers();
			this.openSubmenu(this.submenu, this.button, this.links);
		}
	}

	onResize() {
		// this.setSubmenuEndHeight()
	}

	// setSubmenuEndHeight() {
	//   let headerHeight = 0
	//   for (let element of this.header) {
	//     headerHeight += element.offsetHeight
	//   }

	//   let topMenuHeight = 0
	//   for (let element of this.topMenu) {
	//     topMenuHeight += element.offsetHeight
	//   }

	//   const height = window.innerHeight - (headerHeight + topMenuHeight)

	//   if (minTablet.matches) {
	//     this.submenu.style.height = `${height}px`
	//   } else {
	//     this.submenu.style.height = `auto`
	//   }

	//   const megaContainer = this.submenu.querySelector(
	//     '[data-megamenu-container]'
	//   )
	//   if (megaContainer) {
	//     if (minTablet.matches) {
	//       megaContainer.style.minHeight = `${height - 2}px`
	//     } else {
	//       megaContainer.style.minHeight = `auto`
	//     }
	//   }
	// }

	openSubmenu(submenu, button, links) {
		submenu.setAttribute("data-open", "true");
		button.setAttribute("aria-expanded", "true");
		submenu.hidden = false;

		// if (this.submenuOptions.useHeight) {
		//   this.setSubmenuEndHeight()
		// }

		setTimeout(() => {
			submenu.style.opacity = 1;
			submenu.classList.add("is-open");
			document.body.classList.add("is-megamenu-open");
			// disableBodyScroll(this.megamenu)

			links.forEach((el) => {
				el.setAttribute("tabindex", "0");
			});
		}, 100);

		document.addEventListener("keydown", this.trapFocusInSubmenu);
		document.addEventListener("click", this.handleOutsideClick);
	}

	closeSubmenu(submenu, button, links) {
		submenu.style.opacity = 0;

		// if (minTablet.matches) {
		//   if (this.submenuOptions.useHeight) {
		//     submenu.style.height = '0px'
		//   } else {
		//     submenu.style.height = 'auto'
		//   }
		// }

		document.body.classList.remove("is-megamenu-open");
		enableBodyScroll(this.megamenu);

		submenu.classList.remove("is-open");

		setTimeout(() => {
			submenu.hidden = true;
			submenu.setAttribute("data-open", "false");
			button.setAttribute("aria-expanded", "false");
			links.forEach((el) => {
				el.setAttribute("tabindex", "-1");
			});
		}, 300);

		document.removeEventListener("keydown", this.trapFocusInSubmenu);
		document.removeEventListener("click", this.handleOutsideClick);
	}

	closeOthers() {
		const submenusToClose = this.submenus.filter((el) => el !== this.submenu);

		submenusToClose.forEach((submenu) => {
			if (submenu.dataset.open === "true") {
				const button = submenu.previousElementSibling;
				const links = [...submenu.querySelectorAll("a")];

				this.closeSubmenu(submenu, button, links || []);
			}
		});
	}

	onClick(e) {
		if (!e.target.dataset.submenuButton) return;

		this.toggleSubmenu();
	}

	init() {
		this.closeSubmenu(this.submenu, this.button, this.links);
		this.button.addEventListener("click", this.boundOnClick);
	}

	destroy() {
		this.openSubmenu(this.submenu, this.button, this.links);
		this.button.removeEventListener("click", this.boundOnClick);
	}
}

export default Submenu;
