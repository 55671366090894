class Breadcrumbs {
	constructor(el) {
		this.breadcrumbs = el;

		this.breadcrumbsScroll = this.breadcrumbs.querySelector(
			"[data-breadcrumb-scroll]",
		);
		this.breadcrumbsWrap = this.breadcrumbs.querySelector(
			"[data-breadcrumbs-wrap]",
		);

		window.addEventListener("resize", this.onResize.bind(this));
		this.onResize();
	}

	onResize() {
		if (this.breadcrumbsWrap.scrollWidth > this.breadcrumbsWrap.clientWidth) {
			this.breadcrumbsWrap.classList.add("c-breadcrumbs__wrap--scrollable");
			this.breadcrumbsScroll.classList.add("flex");
			this.breadcrumbsScroll.classList.remove("hidden");
			this.breadcrumbsWrap.scrollLeft =
				this.breadcrumbsWrap.scrollWidth - this.breadcrumbsWrap.clientWidth;
		} else {
			this.breadcrumbsWrap.classList.remove("c-breadcrumbs__wrap--scrollable");
			this.breadcrumbsScroll.classList.remove("flex");
			this.breadcrumbsScroll.classList.add("hidden");
		}
	}
}

export default Breadcrumbs;
