import Menu from "./components/burgerMenu";
import Search from "./components/headerSearch";

class MenuSimple {
	constructor(el) {
		this.searchInitialized = false;
		this.menu = new Menu(el, {
			onOpenCallback: () => this.closeSearchIfOpen(),
		});
		this.search = new Search(el, {
			onSearchOpenCallback: () => this.menu.close(),
		});

		/* Initialize */
		this.menu.init();
		this.search.initSearch();
		document.body.classList.add("has-js-menu");
	}

	closeSearchIfOpen() {
		if (this.search.isSearchOpen) {
			this.search.closeSearch();
		}
	}
}

export default MenuSimple;
