class Table {
	constructor(el) {
		this.figure = el;

		this.table = this.figure.querySelector("table");

		this.scrollContainer = document.createElement("div");
		this.scrollContainer.classList.add("wp-block-table-scroll-container");
		this.figure.insertAdjacentElement("beforebegin", this.scrollContainer);

		this.checkCaption();

		// this.addScrollContainer()

		window.addEventListener("resize", this.onResize.bind(this));
		this.onResize();
	}

	checkCaption() {
		if (this.figure.querySelector("figcaption")) {
			this.figure.classList.add("wp-block-table--contains-caption");
		}
	}

	//   addScrollContainer() {
	//     const el = document.createElement('div')
	//     el.classList.add('wp-block-table__scroll-container')
	//     this.figure.insertAdjacentElement('beforebegin', el)
	//   }

	onResize() {
		if (this.figure.scrollWidth > this.figure.offsetWidth) {
			this.figure.classList.add("wp-block-table--scrollable");
			this.scrollContainer.classList.add(
				"wp-block-table-scroll-container--scrollable",
			);
		} else {
			this.figure.classList.remove("wp-block-table--scrollable");
			this.scrollContainer.classList.remove(
				"wp-block-table-scroll-container--scrollable",
			);
		}
	}
}

export default Table;
