import { flare } from "@flareapp/flare-client";

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (process.env.NODE_ENV === "production") {
	flare.light();
}

/* Optional */
import fitvids from "fitvids";
import svg4everybody from "svg4everybody";
import "focus-visible";

/* Import components as needed */
import Breadcrumbs from "./components/breadcrumbs";
import Card from "./components/card";
import CaseStudy from "./components/casestudy";
import Counter from "./components/counter";
import Filter from "./components/filter";
import FilterTags from "./components/filterTags";
import InstagramShare from "./components/instagramShare";
import MenuDropdown from "./components/menu/menuDropdown";
import MenuSimple from "./components/menu/menuSimple";
import onLoad from "./components/onLoad";
import Pagination from "./components/pagination";
import ScrollProgress from "./components/scrollProgress";
import SearchSidebar from "./components/searchSidebar";
import ShareButtonsMore from "./components/shareButtonsMore";
import StickyNavigation from "./components/stickyNavigation";
import Table from "./components/table";
import { initialize, initializeWithClass } from "./helpers/init";
import loadBundles from "./helpers/loadBundles";

document.body.classList.remove("no-js"); // Path might differ based on your folder structure

// document.body.classList.add('low-energy')

svg4everybody({
	polyfill: true,
});

fitvids();

/* Initialize components as needed */
initialize("menu", MenuDropdown);
initialize("menu-simple", MenuSimple);
initialize("card", Card);
initialize("filter", Filter);
initialize("instagram-share", InstagramShare);
initialize("share-buttons", ShareButtonsMore);
initialize("search-sidebar", SearchSidebar);
initialize("breadcrumbs", Breadcrumbs);
initialize("filter-tags", FilterTags);
initialize("scroll-progress", ScrollProgress);
initializeWithClass(".wp-block-table", Table);
initialize("counter", Counter);
initialize("casestudy", CaseStudy);
initialize("pagination", Pagination);
initialize("sticky-navigation", StickyNavigation);

onLoad();

/*
  ** Conditionally load bundles **
  ---
  Only do this if you have large components or dependencies that you don't want to load on every page (e.g. a Vue application). Otherwise import and initialise them in the normal way, above.
*/
const bundles = [
	{
		selector: "accordion",
		fileName: "accordion",
	},
	{
		selector: "tabs",
		fileName: "tabs",
	},
	{
		selector: "accordion-tabs",
		fileName: "accordionTabs",
	},
	{
		selector: "parallax",
		fileName: "parallax",
	},
	{
		selector: "hero-video",
		fileName: "heroVideo",
	},
	{
		selector: "hero-video-modal",
		fileName: "heroVideoModal",
	},
	{
		selector: "hero-carousel",
		fileName: "heroCarousel",
	},
	{
		selector: "gallery",
		fileName: "customGallery",
	},
];
loadBundles(bundles);
