import { minTablet } from "../../../helpers/resize";

class MegaMenuTabs {
	constructor(megamenu) {
		this.tabButtons = Array.from(megamenu.querySelectorAll("[data-tab-id]"));
		this.mobileTabButtons = Array.from(
			megamenu.querySelectorAll("[data-tab-id-mobile]"),
		);
		this.tabPanels = Array.from(
			megamenu.querySelectorAll("[data-tab-content]"),
		);
		this.singleItemButton = megamenu.querySelector(
			".megamenu__submenu-item__single",
		);
		this.init();
	}

	init() {
		this.tabButtons.forEach((tabButton, i) => {
			tabButton.addEventListener("click", (e) => {
				e.stopPropagation();
				this.selectTab(i, false);
			});
		});

		this.mobileTabButtons.forEach((mobileTabButton, i) => {
			mobileTabButton.addEventListener("click", () => this.selectTab(i, true));
		});

		if (minTablet.matches) {
			this.selectTab(0, false); // Default behavior for desktop
		} else {
			// Default behavior for mobile: hide all panels initially
			this.tabPanels.forEach((tabpanel) => {
				tabpanel.hidden = true;
			});

			// If the `.megamenu__submenu-item__single` button exists, display its corresponding panel
			if (this.singleItemButton) {
				const index = this.mobileTabButtons.indexOf(this.singleItemButton);
				this.selectTab(index, true);
			}
		}
	}

	isTabSelected(tabButton) {
		return tabButton.getAttribute("aria-selected") === "true";
	}

	selectTab(index, isMobileTab) {
		const isSelected = this.isTabSelected(this.mobileTabButtons[index]);

		if (isMobileTab) {
			this.tabButtons.forEach((tabButton) => {
				tabButton.setAttribute("aria-selected", false);
			});

			this.mobileTabButtons.forEach((mobileTabButton, i) => {
				if (i === index) {
					mobileTabButton.setAttribute("aria-selected", !isSelected);
				} else {
					mobileTabButton.setAttribute("aria-selected", false);
				}
			});

			this.tabPanels.forEach((tabpanel, i) => {
				tabpanel.hidden = i !== index || isSelected;
			});
		} else {
			this.tabButtons.forEach((tabButton, i) => {
				const shouldSelect = i === index;
				tabButton.setAttribute("aria-selected", shouldSelect);
			});

			this.mobileTabButtons.forEach((mobileTabButton) => {
				mobileTabButton.setAttribute("aria-selected", false);
			});

			this.tabPanels.forEach((tabpanel, i) => {
				tabpanel.hidden = i !== index;
			});
		}

		// Adjust the tabindex for links inside the tab panel
		this.tabPanels.forEach((tabpanel) => {
			const linksInTabPanel = Array.from(
				tabpanel.querySelectorAll('a[role="menuitem"]'),
			);

			if (tabpanel.hidden) {
				linksInTabPanel.forEach((link) => link.setAttribute("tabindex", "-1"));
			} else {
				linksInTabPanel.forEach((link) => link.setAttribute("tabindex", "0"));
			}
		});

		this.shiftFocusToPanel(index);
	}

	shiftFocusToPanel(index) {
		const tabpanel = this.tabPanels[index];
		if (!tabpanel.hidden) {
			// Set the tabpanel itself to be momentarily focusable
			tabpanel.setAttribute("tabindex", "-1");

			// Focus the panel but not its children
			tabpanel.focus();

			// Remove the tabindex after focusing so it doesn't stay focusable
			setTimeout(() => {
				tabpanel.removeAttribute("tabindex");
			}, 0);
		}
	}
}

document
	.querySelectorAll("[data-megamenu]")
	.forEach((megamenu) => new MegaMenuTabs(megamenu));
