import { minDesktop } from "../helpers/resize";
import Dropdown from "./dropdown";

class Filter {
	constructor(el) {
		this.el = el;
		this.dropdown = new Dropdown(el);
		minDesktop.addEventListener("change", this.onResize.bind(this));
		this.onResize();
	}

	onResize() {
		if (!minDesktop.matches) {
			this.dropdown.initialize();
		} else {
			this.dropdown.destroy();
		}
	}
}

export const render = (el) => new Filter(el);
export default Filter;
