class Dropdown {
	constructor(el) {
		this.el = el;
		this.button = el.querySelector("[data-btn]");
		this.content = el.querySelector("[data-content]");
		this.innerContent = el.querySelector("[data-inner-content]");
		this.links = [...el.querySelectorAll("a")];
		this.text = el.querySelector("[data-text]");
		this.isOpen = false;

		/* Events */
		this.handleClick = this.onClick.bind(this);
	}

	open() {
		this.content.hidden = false;
		this.links.forEach((el) => el.removeAttribute("tabindex"));
		this.el.classList.add("is-open");

		setTimeout(() => {
			this.content.style.height = `${this.innerContent.clientHeight}px`;
		}, 100);
	}

	close() {
		this.content.style.height = 0;

		setTimeout(() => {
			this.content.hidden = true;
			this.links.forEach((el) => el.setAttribute("tabindex", -1));
			this.el.classList.remove("is-open");
		}, 300);
	}

	onClick() {
		if (!this.isOpen) {
			this.open();
			this.isOpen = true;
		} else {
			this.close();
			this.isOpen = false;
		}
	}

	initialize() {
		this.button.addEventListener("click", this.handleClick);
		this.button.hidden = false;
		this.close();
		this.isOpen = false;
	}

	destroy() {
		this.button.removeEventListener("click", this.handleClick);
		this.button.hidden = true;
		this.content.hidden = false;
		this.content.removeAttribute("style");
		this.links.forEach((el) => el.removeAttribute("tabindex"));
	}
}

export default Dropdown;
